import React, { useContext } from "react";

import { navigate } from "gatsby";

import UserContext from "@/contexts/User/UserContext";
import { marketingStepsForFixPaymentFromLandingPage } from "@/marketing/utils";

import { BANNER_BLOCK_URL } from "./constants";
import bannerOneX from "./images/banner.jpg";
import bannerTwoX from "./images/banner@2x.jpg";
import bannerThreeX from "./images/banner@3x.jpg";

import "./styles.scss";

const BannerBlock = () => {
  const { userID } = useContext(UserContext);
  return (
    <div className="banner-block__container">
      <picture
        className="banner-block"
        onClick={() => {
          navigate(BANNER_BLOCK_URL);
          marketingStepsForFixPaymentFromLandingPage({ userId: userID?.toString(), bannerName: "secret_ideal_stretching_5000" });
        }}
      >
        <source
          srcSet={`${bannerOneX},
              ${bannerTwoX} 2x,
              ${bannerThreeX} 3x`}
        />
        <img src={bannerOneX} alt="" />
      </picture>
    </div>
  );
};

export default BannerBlock;
