import React, { useContext } from "react";

import { navigate } from "gatsby";

import UserContext from "@/contexts/User/UserContext";
import { marketingStepsForFixPaymentFromLandingPage } from "@/marketing/utils";

import { EXPERT_LISTING_BANNER_DATA } from "./constants";

import "./styles.scss";

const ExpertListingBanner = () => {
  const { userID } = useContext(UserContext);
  const imageData = EXPERT_LISTING_BANNER_DATA.image;

  return (
    <div
      className="expert-listing-banner"
      onClick={() => {
        navigate(EXPERT_LISTING_BANNER_DATA.url);
        marketingStepsForFixPaymentFromLandingPage({ userId: userID?.toString(), bannerName: "secret_ideal_stretching_banner_5000" });
      }}
    >
      <picture>
        <source
          srcSet={`${imageData.mobile.oneX} 1x,
            ${imageData.mobile.twoX} 2x,
            ${imageData.mobile.threeX} 3x`}
          media="(max-width: 767px)"
        />
        <source
          srcSet={`${imageData.tablet.oneX} 1x,
            ${imageData.tablet.twoX} 2x,
            ${imageData.tablet.threeX} 3x`}
          media="(min-width: 768px)"
        />
        <img
          src={imageData.mobile.oneX}
          alt=""
          className="expert-listing-banner__image"
        />
      </picture>
    </div>
  );
};

export default ExpertListingBanner;
