import bannerMobile1x from "./images/mobile.jpg";
import bannerMobile2x from "./images/mobile@2x.jpg";
import bannerMobile3x from "./images/mobile@3x.jpg";
import bannerTablet1x from "./images/tablet.jpg";
import bannerTablet2x from "./images/tablet@2x.jpg";
import bannerTablet3x from "./images/tablet@3x.jpg";
import { ExpertListingBannerImagesType } from "./types";

export const EXPERT_LISTING_BANNER_DATA: ExpertListingBannerImagesType = {
  url: "/landing/your-perfect-love",
  image: {
    mobile: {
      oneX: bannerMobile1x,
      twoX: bannerMobile2x,
      threeX: bannerMobile3x,
    },
    tablet: {
      oneX: bannerTablet1x,
      twoX: bannerTablet2x,
      threeX: bannerTablet3x,
    },
  },
};
