import React from "react";

import BannerBlock from "./bannerBlock";
import ButtonBannerBlock from "./buttonBannerBlock";
import DiscountPromoBlock from "./DiscountPromoBlock";
import PromoWithoutPrePayment from "./PromoWithoutPrePayment";

const PromoBlock = () => {
  if (
    process.env.GATSBY_IS_NO_FIRST_PAYMENT_PROMO_ACTIVE === "true"
    || process.env.GATSBY_IS_NO_FIRST_PAYMENT_ACTION_ACTIVE === "true"
  ) {
    return <PromoWithoutPrePayment />;
  }

  if (process.env.GATSBY_IS_DISCOUNT_PROMO_ACTIVE === "true") {
    return <DiscountPromoBlock />;
  }

  if (process.env.GATSBY_IS_BANNER_BLOCK_ACTIVE === "true") {
    return <BannerBlock />;
  }

  if (process.env.GATSBY_IS_BUTTON_BANNER_ACTIVE === "true") {
    return <ButtonBannerBlock />;
  }

  return null;
};

export default PromoBlock;
